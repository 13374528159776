import React from 'react'

export default function Trancparency() {
    return (
        <>
            <br /><br />
            <section className="space pb-0" id='Transparency'>
                <div className="container z-index-common space">
                    <div className="cta-wrap2">
                        <div className="cta-border-shape" style={{ backgroundImage: `url("assets/img/bg/cta-wrap2-bg-shape2.png")`, backgroundRepeat: "no-repeat" }} />
                        <div className="cta-wrap-content" style={{ backgroundImage: `url("assets/img/bg/cta-wrap2-bg-shape.png")`, backgroundRepeat: "no-repeat" }}>
                            <div className="cta-border-shape2" style={{ backgroundImage: `url("assets/img/theme-img/cta-wrap2-border-shape.svg")` }} />
                            <div className="title-area mb-0 custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <br />
                                <h2 className="sec-title">Transparency 
                                </h2>
                                <p className='mt-30 mb-30'>YBLWin is a fully decentralized & transparent gaming protocol with all the transaction data accessible on blockchain. </p>
                                {/* <p className="mt-30 mb-30">0x30049217809B91B3e3653c447A05d47515ac5174</p> */}
                                <br />
                                <div className="btn-wrap justify-content-center">
                                    {/* <a href="contact.html" className="th-btn">JOIN
                                        COMMUNITY <i className="fa-solid fa-arrow-right ms-2" /></a> */}
                                    {/* <a href="tournament.html" className="th-btn style-border"><span className="btn-border">CREATE TOURNAMENT <i className="fa-solid fa-arrow-right ms-2" />
                                    </span>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
