import React, { useContext, useState, useEffect } from 'react'
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate } from 'react-router-dom';
import Popover from "react-bootstrap/Popover";
import { OverlayTrigger } from 'react-bootstrap';
export default function Header() {
    const { account, connectMetamask, disconnectNow, dbuser, dbuserload, formatAddress } = useContext(ProjectContext);
    const [showMenu, setShowMenu] = useState(false);
    var navigate = useNavigate();

    const logout = () => {
        disconnectNow();
        navigate('/')
    }
    useEffect(() => {
        console.log('dbuserload', dbuserload);
        if (dbuserload && dbuser !== null) {
            console.log("/dashboard");
            navigate("/dashboard",dbuserload && dbuser !== null,dbuserload,dbuser);
        } else if (dbuserload && account && dbuser === null) {
            console.log("/login",dbuserload,account,dbuser);
            navigate("/login");
        }
    }, [dbuser, dbuserload, account])
    const popoverTop = (
        <Popover
            id="popover-positioned-top"
            className="popover"
            title="Popover top"
        >
            <strong>
                <a href="https://help.yblwin.ai/" target="_blank" rel="noreferrer" style={{ fontSize: "12px" }}>
                    Need <br /> Help ?
                </a>
            </strong>
        </Popover>
    );
    return (
        <>
            <div>
                <div className={showMenu ? "th-menu-wrapper th-body-visible" : "th-menu-wrapper"}>
                    <div className="th-menu-area text-center"><button className="th-menu-toggle" onClick={() => setShowMenu(false)}><i className="fal fa-times" /></button>
                        <div className="mobile-logo"><Link to="/"><span data-mask-src="/assets/img/logo.png" className="logo-mas" /><img src="/assets/img/logo.png" alt="Bame" /></Link></div>
                        <div className="th-mobile-menu">
                            <ul>
                                <li><a href='#About' onClick={() => setShowMenu(false)}>About Us</a></li>
                                <li><a href='#Games' onClick={() => setShowMenu(false)}>Games</a></li>
                                <li><a href='#Features' onClick={() => setShowMenu(false)}>Features</a></li>
                                <li><a href='#Transparency' onClick={() => setShowMenu(false)}>Transparency</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <header className="th-header header-layout2">
                    <div className="sticky-wrapper">
                        <div className="container">
                            <div className="row gx-0 justify-content-between">
                                <div className="col-lg-auto col">
                                    <div className="header-logo-wrap">
                                        <div className="header-logo"><Link to="/"><span data-mask-src="/assets/img/logo.png" className="logo-mask" /> <img src="/assets/img/logo.png" alt="Bame" /></Link></div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="menu-area">
                                        <div className="row gx-0 align-items-center justify-content-lg-between justify-content-end">
                                            <div className="col-auto">
                                                <nav className="main-menu d-none d-lg-inline-block">
                                                    <ul>
                                                        <li><a href='#About'>About Us</a></li>
                                                        <li><a href='#Games'>Games</a></li>
                                                        <li><a href='#Features'>Features</a></li>
                                                        <li><a href='#Transparency'>Transparency</a></li>
                                                    </ul>
                                                </nav>
                                                <div className="header-button d-flex d-lg-none"><button type="button" className="th-menu-toggle" onClick={() => setShowMenu(true)}><span className="btn-border" /><i className="far fa-bars" /></button></div>
                                            </div>
                                            <div className="col-auto  d-xl-none d-block">
                                                <div className="header-button">

                                                    <div className="d-xl-none d-block">
                                                        {account ?
                                                            <>
                                                                <div className="header-button d-flex d-lg-none">
                                                                    <button type="button" className="th-menu-toggle" onClick={() => logout()}><span className="btn-border" /><i className="far fa-sign-out" />
                                                                    </button>
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="header-button d-flex d-lg-none">
                                                                <button type="button" className="th-menu-toggle" onClick={() => connectMetamask()}><span className="btn-border" /><i className="far fa-wallet" />
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto d-none d-xl-block d-md-block">
                                                <div className="header-button">
                                                    {/* <button type="button" className="simple-icon searchBoxToggler d-none d-xxl-block"><i className="far fa-search" /></button> */}
                                                    <div className="d-xl-block d-md-block d-none">
                                                        {account ?
                                                            <>
                                                                <div className='d-flex'>
                                                                    <Link to='/login' className="th-btn">{formatAddress(account)} <i className='fa fa-copy me-1' /></Link>
                                                                    <button type="button" className="th-btn" onClick={() => logout()}>Logout<i className="fa-solid fa-sign-out" /></button>
                                                                </div>
                                                            </>
                                                            :
                                                            <button className="th-btn" onClick={() => connectMetamask()}><i className="fa-brands fa-twitch me-1" />Wallet Connect</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <OverlayTrigger trigger="click" placement="top" overlay={popoverTop}>
                    {/* <img width={90} height={80} src="assets/assets/img/chat-boat.png" alt="" className='sticky' style={{ cursor: 'pointer', float: "right", position: "absolute", bottom: "0", zIndex: "999", paddingRight: "10px", right: "0" }} /> */}
                    <img
                        width={70}
                        height={70}
                        src="assets/img/chat_bot.png"
                        alt=""
                        className="sticky"
                    />
                </OverlayTrigger>
            </div>

        </>
    )
}
