import React from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import Table from './Table'
import DashboardHeader from '../Comman/DashboardHeader'

export default function GamingWallet() {
    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader/> */}
                <br /><br />
                <div className="contact-sec-1 bg-repeat overflow-hidden space" style={{ backgroundImage: `url("assets/img/bg/jiji-bg2.png")` }}>
                    <div className="container-fluid p-0">
                        <div className="contact-wrap-1">
                            <div className="row justify-content-around">
                                <div className="col-xxl-5 col-xl-6">
                                    <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <h2 className="sec-title text-white">Gaming Wallet</h2>
                                    </div>
                                    <form action="https://html.themeholy.com/bame/demo/mail.php" method="POST" className="contact-form ajax-contact pb-xl-0 space-bottom custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <div className="row">
                                            <span className='text-end'>Balance: $0.00</span>
                                            <div className="form-group style-border2 col-md-12"><input type="number" className="form-control" name="email" id="email" placeholder="Amount" /> <i className="fal fa-usd" /></div>
                                            {/* <div className="form-group style-border2 col-md-12"><input type="text" className="form-control" name="email" id="email" placeholder="ID" /> <i className="fal fa-envelope" /></div> */}
                                            {/* <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Admin Fees:</span> <span>1000 USDT</span></div>
                                            <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Gaming Wallet:</span> <span>1000 USDT</span></div>
                                            <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Recived Amount:</span> <span>1000 USDT</span></div> */}

                                            {/* <div className="form-group style-border2 col-md-6"><input type="text" className="form-control" name="number" id="number" placeholder="Phone Number" /> <i className="fal fa-phone" /></div>
                                            <div className="col-md-6">
                                                <div className="form-group style-border2"><select className="form-select" aria-label="Default select example">
                                                    <option selected="selected">Subject</option>
                                                    <option value={1}>Esports</option>
                                                    <option value={2}>Tournament</option>
                                                    <option value={3}>Sports</option>
                                                </select> <i className="fal fa-angle-down" /></div>
                                            </div> */}
                                            {/* <div className="col-12 form-group style-border2"><textarea placeholder="Write Massage...." className="form-control" defaultValue={""} /> <i className="far fa-pencil" /></div> */}
                                            <div className="form-btn col-12 mt-3">
                                                <button className="th-btn disabled" disabled>Transfer <i className="fa-solid fa-arrow-right ms-2" /></button>
                                            </div>
                                            <div className="form-btn col-12 mt-3">
                                                <span>NOTE : It should be noted that once you money front your game wallet to your gaming platform you can not take it back.</span>
                                            </div>
                                        </div>
                                        <p className="form-messages mb-0 mt-3" />
                                    </form>
                                </div>
                                <div className="col-xxl-5 col-xl-6">
                                    <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <h2 className="sec-title text-white">Frequently Asked Questions About Our Esports &amp; Gaming Site
                                        </h2>
                                    </div>
                                    <div className="accordion custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s" id="faqAccordion">
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-1"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">What game are you currently
                                                obsessed with, and why?</button></div>
                                            <div id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">The current meta in [popular game] heavily favors [specific
                                                        characters/weapons/strategies]. This has led to a shift in gameplay
                                                        dynamics, with players adapting their strategies to stay competitive.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-2"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">How do professional esports players train for
                                                tournaments?</button></div>
                                            <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">The current meta in [popular game] heavily favors [specific
                                                        characters/weapons/strategies]. This has led to a shift in gameplay
                                                        dynamics, with players adapting their strategies to stay competitive.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-3"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">What distinguishes a successful esports team from the
                                                rest?</button></div>
                                            <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">The current meta in [popular game] heavily favors [specific
                                                        characters/weapons/strategies]. This has led to a shift in gameplay
                                                        dynamics, with players adapting their strategies to stay competitive.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Table /> */}
            </div>
        </>
    )
}
