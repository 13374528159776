import React, { useContext, useEffect, useState } from 'react'
import Footer from './Comman/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ProjectContext } from '../ProjectContext'

export default function Overview() {

    const { account, toastSuccess ,setaccount} = useContext(ProjectContext)
    const [dname, setdname] = useState(null);

    var navigate = useNavigate();

    const godata = async () => {
        setaccount(dname)
        navigate("/dashboard")
        toastSuccess("Here you go!")
    }
    return (
        <>
            {/* <Header /> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="contact-sec-1 bg-repeat overflow-hidden space" style={{ backgroundImage: `url("assets/img/bg/jiji-bg2.png")` }}>
                <div className="container-fluid p-0">
                    <div className="contact-wrap-1">
                        <div className="row justify-content-around">
                            <div className="col-xxl-5 col-xl-6">
                                <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                    <h2 className="sec-title text-white">Overview </h2>
                                </div>
                                <div className="row">
                                    <div className="form-group style-border2 col-md-12">
                                        <input type="text" className="form-control" name="text" id="text" placeholder="Enter User Address" onChange={(e) => setdname(e.target.value)} value={dname} /> <i className="fal fa-user" />
                                    </div>

                                    <div className="form-btn col-12 mt-3 my-5">
                                        <button onClick={() => godata()} className="th-btn">Go <i className="fa-solid fa-arrow-right ms-2" /></button>
                                    </div>
                                </div>
                                <p className="form-messages mb-0 mt-3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
