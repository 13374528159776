import React, { useState } from 'react'

export default function BigMatch() {
    const [showGame, setShowGame] = useState('');
    const handleTabClick = (Game) => {
        setShowGame(Game);
    };
    return (
        <>
            <section className="game-area-3 space-bottom overflow-hidden" id='Games'>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-auto">
                            <div className="title-area custom-anim-top wow" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <h2 className="sec-title">Our Most Played Games<span className="text-theme"></span></h2>
                                <p>YBLWin offers a number of blockchain games to the worldwide gaming community.
                                </p>
                            </div>
                            <div className="game-filter-btn filter-menu filter-menu-active custom-anim-top wow">
                                <button data-filter="*" className={showGame === 'Game1' || showGame === '' ? "tab-btn active" : "tab-btn"} type="button" onClick={() => handleTabClick('Game1')}>Most Played</button>
                                <button data-filter=".cat1" className={showGame === 'Game2' ? "tab-btn active" : "tab-btn"} type="button" onClick={() => handleTabClick('Game2')}>Arcade</button>
                                <button data-filter=".cat2" className={showGame === 'Game3' ? "tab-btn active" : "tab-btn"} type="button" onClick={() => handleTabClick('Game3')}>Card Games</button>
                                <button data-filter=".cat3" className={showGame === 'Game4' ? "tab-btn active" : "tab-btn"} type="button" onClick={() => handleTabClick('Game4')}>Racing Games</button>
                                <button data-filter=".cat4" className={showGame === 'Game5' ? "tab-btn active" : "tab-btn"} type="button" onClick={() => handleTabClick('Game5')}>Classic Games</button>
                            </div>
                        </div>
                    </div>
                    <div className={showGame === 'Game1' || showGame === '' ? "d-block" : "d-none"}>
                        <div className="row gy-4 filter-active">
                            <div className="col-lg-4 col-md-6 filter-item cat1">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/teen_patti.png"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/teen_patti.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Teen Patti
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/roulette.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Roulette </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat3">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/fantasy_soccer.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Fantasy Soccer
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat3 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href="">
                                        <span className="game-rating"><i className="fas fa-star" />
                                            4.5</span> <img src="assets/img/speed_pepe.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Speed Pepe
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat1 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/street_fighter.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Street Fighter
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/cannon_strike.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Cannon Strike</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={showGame === 'Game2' ? "d-block" : "d-none"}>
                        <div className="row gy-4 filter-active">
                            <div className="col-lg-4 col-md-6 filter-item cat1">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/tic_tac_toe.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Tic-Tac-Toe

                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/rolling_ball_3D.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Rolling Ball 3D
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat3">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/temple_run_2049.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Temple Run 2049
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat3 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href="">
                                        <span className="game-rating"><i className="fas fa-star" />
                                            4.5</span> <img src="assets/img/bubble_bash.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Bubble Bash
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat1 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/cart_wars.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title">
                                            <a href="">Cart Wars
                                            </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/fruit_cut.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Fruit Cut</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={showGame === 'Game3' ? "d-block" : "d-none"}>
                        <div className="row gy-4 filter-active">
                            <div className="col-lg-4 col-md-6 filter-item cat1">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/poker_game.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Poker
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/blackjack.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Blackjack </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat3">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/rummy.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Rummy
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat3 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href="">
                                        <span className="game-rating"><i className="fas fa-star" />
                                            4.5</span> <img src="assets/img/solitaire.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Solitaire
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat1 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/hearts.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Hearts
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/uno.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">UNO</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={showGame === 'Game4' ? "d-block" : "d-none"}>
                        <div className="row gy-4 filter-active">
                            <div className="col-lg-4 col-md-6 filter-item cat1">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/death_race.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Death Race
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/need_for_speed.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Need For Speed </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat3">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/road_fighter.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Road Fighter
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat3 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href="">
                                        <span className="game-rating"><i className="fas fa-star" />
                                            4.5</span> <img src="assets/img/moto_gp.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Moto GP

                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat1 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/f1_grand_racing.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">F1 Grand Racing
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/mad_max.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Mad Max</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={showGame === 'Game5' ? "d-block" : "d-none"}>
                        <div className="row gy-4 filter-active">
                            <div className="col-lg-4 col-md-6 filter-item cat1">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/chess_game.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Chess
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/ludo_game.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Ludo </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat3">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/pool_master.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Pool Master
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat3 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href="">
                                        <span className="game-rating"><i className="fas fa-star" />
                                            4.5</span> <img src="assets/img/tetris.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Tetris


                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat1 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/carrom.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Carrom
                                        </a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 filter-item cat2 cat4">
                                <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                    <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href=""><span className="game-rating"><i className="fas fa-star" />
                                        4.5</span> <img src="assets/img/checkers.png" alt="game image" /></a></div>
                                    <div className="game-card-details">
                                        <h3 className="box-title"><a href="">Checkers </a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
