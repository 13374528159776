import React from 'react'

export default function Table() {
    return (
        <> 
            <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                <div className="container">
                    <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s"><span className="sub-title style2"># Point Table</span>
                        <h2 className="sec-title">Game On, Power Up, Win Big </h2>
                    </div>
                    <div className="table-responsive">
                        <table className="table tournament-table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Team Squad</th>
                                    <th scope="col">Matches</th>
                                    <th scope="col">Matches</th>
                                    <th scope="col">Place PTS.</th>
                                    <th scope="col">Finishes</th>
                                    <th scope="col">Total PTS.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td><a href="tournament.html"><img src="assets/img/tournament/1-1.png" alt="img" />PRO
                                        Player</a></td>
                                    <td>4</td>
                                    <td>0</td>
                                    <td>21</td>
                                    <td>25</td>
                                    <td>47</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td><a href="tournament.html"><img src="assets/img/tournament/1-2.png" alt="img" />The Lion
                                        King</a></td>
                                    <td>4</td>
                                    <td>1</td>
                                    <td>29</td>
                                    <td>16</td>
                                    <td>45</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td><a href="tournament.html"><img src="assets/img/tournament/1-3.png" alt="img" />The
                                        Assassin King</a></td>
                                    <td>4</td>
                                    <td>1</td>
                                    <td>25</td>
                                    <td>20</td>
                                    <td>45</td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td><a href="tournament.html"><img src="assets/img/tournament/1-4.png" alt="img" />Cyberpunk</a></td>
                                    <td>4</td>
                                    <td>0</td>
                                    <td>32</td>
                                    <td>12</td>
                                    <td>44</td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td><a href="tournament.html"><img src="assets/img/tournament/1-5.png" alt="img" />Team
                                        Gorilla</a></td>
                                    <td>4</td>
                                    <td>1</td>
                                    <td>24</td>
                                    <td>19</td>
                                    <td>43</td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td><a href="tournament.html"><img src="assets/img/tournament/1-6.png" alt="img" />King Of
                                        Badgamer</a></td>
                                    <td>4</td>
                                    <td>0</td>
                                    <td>17</td>
                                    <td>21</td>
                                    <td>38</td>
                                </tr>
                                <tr>
                                    <th scope="row">7</th>
                                    <td><a href="tournament.html"><img src="assets/img/tournament/1-7.png" alt="img" />Team
                                        Ninja</a></td>
                                    <td>4</td>
                                    <td>0</td>
                                    <td>18</td>
                                    <td>18</td>
                                    <td>36</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}
