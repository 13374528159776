import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';

export default function Profiledata() {
    const { account, copyaddress, dbuser, toastSuccess, toastError, formatAddress, getuserDB } = useContext(ProjectContext)
    const [uname, setuname] = useState(null);
    const [dname, setdname] = useState(null);
    const [email, setemail] = useState(null);
    const [number, setnumber] = useState(null);
    const [reff_address, setreff_address] = useState(null);
    const [address, setaddress] = useState(null);

    const updateData = async () => {
        const isValidWeb3Address = taddress => /^0x[a-fA-F0-9]{40}$/.test(taddress);
        if (!isValidWeb3Address(address)) {
            toastError("Invalid Address");
            return
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "updatef",
                key: process.env.REACT_APP_KEY,
                address: address,
                u_id: dbuser.user_id,
                uname: uname,
                number: number,
                dname: dname,
                email: email
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess(res.data.data);
                getuserDB()
            });

    }
    useEffect(() => {
        if (dbuser !== null) {
            setemail(dbuser.email)
            setdname(dbuser.dname)
            setuname(dbuser.uname)
            setreff_address(dbuser.referral_id)
            setaddress(dbuser.address)
            setnumber(dbuser.m_number)
        } else {
            setemail('')
            setdname('')
            setuname('')
            setreff_address('')
            setaddress('')
            setnumber('')
        }
    }, [dbuser])

    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader /> */}
                <br /><br />
                <div className="contact-sec-1 bg-repeat overflow-hidden space" style={{ backgroundImage: `url("assets/img/bg/jiji-bg2.png")` }}>
                    <div className="container-fluid p-0">
                        <div className="contact-wrap-1">
                            <div className="row justify-content-around">
                                <div className="col-xxl-5 col-xl-6">
                                    <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <h2 className="sec-title text-white">Profile Details</h2>
                                    </div>
                                    <div className="row">
                                        <label htmlFor="" className='text-white'>Display Name</label>
                                        <div className="form-group style-border2 col-md-12">
                                            <input type="text" className="form-control" name="text" id="text" placeholder="Display Name" onChange={(e) => setdname(e.target.value)} value={dname} readOnly={dbuser ? dbuser.t_staking > 0 ? true : false : false} /> <i className="fal fa-user" />
                                        </div>
                                        <label htmlFor="" className='text-white'>User Name</label>
                                        <div className="form-group style-border2 col-md-12">
                                            <input type="text" className="form-control" name="uname" id="uname" placeholder="User Name" onChange={(e) => setuname(e.target.value)} value={uname} readOnly={dbuser ? dbuser.t_staking > 0 ? true : false : false} /> <i className="fal fa-address-card" />
                                        </div>
                                        <label htmlFor="" className='text-white'>Email</label>
                                        <div className="form-group style-border2 col-md-12">
                                            <input type="text" className="form-control" name="email" id="email" placeholder="Email" onChange={(e) => setemail(e.target.value)} value={email} readOnly={dbuser ? dbuser.t_staking > 0 ? true : false : false} /> <i className="fal fa-envelope" />
                                        </div>
                                        <label htmlFor="" className='text-white'>Enter Address</label>
                                        <div className="form-group style-border2 col-md-12">
                                            <input type="text" className="form-control" onChange={(e) => setaddress(e.target.value)} value={address} placeholder='Enter Address' readOnly={dbuser ? dbuser.t_staking > 0 ? true : false : false} /> <i className="fal fa-note-sticky" />
                                        </div>
                                        <label htmlFor="" className='text-white'>Enter Mobile Number</label>
                                        <div className="form-group style-border2 col-md-12">
                                            <input type="text" className="form-control" onChange={(e) => setnumber(e.target.value)} value={number} placeholder='Enter Mobile Number' readOnly={dbuser ? dbuser.t_staking > 0 ? true : false : false} /> <i className="fal fa-phone" />
                                        </div>
                                        {/* <div className="form-group style-border2 col-md-12">
                                            <input type="text" className="form-control" name="reff" id="reff" placeholder="Referral ID" value={reff_address} /> <i className="fal fa-user-tie-hair" />
                                        </div> */}
                                        {dbuser ? dbuser.t_staking > 0 ? '' :
                                            <div className="form-btn col-12 mt-3">
                                                <button onClick={() => updateData()} className="th-btn">Submit<i className="fa-solid fa-arrow-right ms-2" /></button>
                                            </div> : ''}
                                    </div>
                                    <p className="form-messages mb-0 mt-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </div >
        </>
    )
}
