import React, { useContext } from 'react'
import { ProjectContext } from '../../ProjectContext';
import CountdownTimer from './CountdownTimer';
import { Link } from 'react-router-dom';

export default function Incomes() {
    const { disconnectNow, dbuser } = useContext(ProjectContext);
    return (
        <>
            <section className="tournament-sec-5 overflow-hidden" data-overlay="title">
                <div className="tournament-sec-bg-shape" />
                <div className="container">
                    <div className="row gy-4 filter-active justify-content-center">
                        <div className="col-lg-12 col-md-12 filter-item tour-cat1 tour-all">
                            <h2 className="sec-title text-center">Rewards</h2>
                            <div className="row gy-30 filter-active justify-content-center">
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/reward.svg" width="100" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4 "><span className="text-theme d-flex">${dbuser !== null
                                                    ? dbuser.staking_inc
                                                    : "0"} (2.5%)</span></h3>
                                                <Link to="/staking-income"><p className="game-content fs-6">Weekly Reward</p></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/rank_reward.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.rank_inc
                                                    : "0"}</span></h3>
                                                <Link to="/roiofroi-income"><p className="game-content fs-6">Team Revenue</p></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/community_reward.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.community_inc
                                                    : "0"}</span></h3>
                                                <Link to="/community-income"><p className="game-content fs-6">Community Bonus</p></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/rank_royalty.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">{dbuser !== null
                                                    ? dbuser.rank_royalty === 0 ? 'N/A' : dbuser.rank_royalty
                                                    : "N/A"}</span></h3>
                                                <Link to="/royalty-income"><p className="game-content fs-6">Leadership Bonus </p></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/achievement.svg" alt="YBL" /></div>
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">{dbuser !== null
                                                    ? dbuser.rank_number_title === null ? '-' : dbuser.rank_number_title
                                                    : "-"}</span></h3>
                                                <Link to="/achievements"><p className="game-content fs-6">Achievement</p></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/royalty_reward.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.team_royalty_inc
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Royalty Bonus</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/r_leg.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.gaming_wallet
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Gaming Wallet</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/r_leg.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.t_withdrawal
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Withdrawal</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="sec-title text-center">Statistics</h2>
                            <div className="row gy-30 filter-active justify-content-center">
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/staking.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.t_staking
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Investment</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/reward.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.community_inc
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Reward</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/direct_player.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">{dbuser !== null
                                                    ? dbuser.d_team
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Direct Team</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/direct_team_business.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.direct_team_business
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Direct Team Business</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/team_player.svg" alt="YBL" /></div>
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">{dbuser !== null
                                                    ? dbuser.team
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Total Team</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/community_team_business.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.new_team_business_third
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Community Team Business</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/current_rank.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">{dbuser !== null
                                                    ? dbuser.rank !== null ? dbuser.rank : '-'
                                                    : "-"}</span></h3>
                                                <p className="game-content fs-6">Current Rank</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/staking.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.t_staking
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Investment</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/direct_team_business.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.direct_team_business
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Direct Team Business</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/team_player.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">{dbuser !== null
                                                    ? dbuser.team
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Team Players</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/roi_to_roi_reward.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.roiofroi_inc
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">ROI of ROI Reward</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/staking_reward.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.staking_inc
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Investment Reward</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/team_business.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.team_business
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Team Business</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/total_business_month.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.new_team_business
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Business(Today)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/total_business_month.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.new_team_business
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Business(Monthly)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/next_rank.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">{dbuser !== null
                                                    ? dbuser.next_rank !== null ? dbuser.next_rank : '-'
                                                    : "-"}</span></h3>
                                                <p className="game-content fs-6">Next Rank</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/leg.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.aleg
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Team A</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/leg.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.bleg
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Team B</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/leg.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.oleg
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Other Team</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/r_leg.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.r_aleg
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Required Team A</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/r_leg.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.r_bleg
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Required Team B</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/r_leg.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.r_oleg
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Required Other Team</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/r_leg.svg" alt="YBL" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                    ? dbuser.pen_withdrawal
                                                    : "0"}</span></h3>
                                                <p className="game-content fs-6">Pending Withdrawal</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

        </>
    )
}
