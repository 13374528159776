import logo from './logo.svg';
import './App.css';
import Dashboard from './Components/Dashboard/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Stake from './Components/Dashboard/Stake';
import Withdrawal from './Components/Dashboard/Withdrawal';
import CommanTable from './Components/Dashboard/CommanTable';
import GamingWallet from './Components/Dashboard/GamingWallet';
import Home from './Components/Home/Home';
import Certificate from './Components/Dashboard/Certificate';
import Login from './Components/Login';
import StakingList from './Components/Dashboard/StakingList';
import StakingIncomeList from './Components/Dashboard/StakingIncomeList';
import Communityincome from './Components/Dashboard/Communityincome';
import ROIofROIincome from './Components/Dashboard/ROIofROIincome';
import Team from './Components/Dashboard/Team';
import TeamRoyaltyincome from './Components/Dashboard/TeamRoyaltyincome';
import Profiledata from './Components/Dashboard/Profiledata';
import TeamDirect from './Components/Dashboard/TeamDirect';
import HelpDesk from './Components/Dashboard/HelpDesk';
import TreeView from './Components/Dashboard/TreeView';
import Achievements from './Components/Dashboard/Achievements';
import WithdrawalHistory from './Components/Dashboard/WithdrawalHistory';
import GamingTransfer from './Components/Dashboard/GamingTransfer';
import Overview from './Components/Overview';

function App() {
  return (
    // <Dashboard />
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/overview" element={<Overview />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/stake" element={<Stake />} />
      <Route path="/comming-soon" element={<HelpDesk />} />
      <Route path="/tree" element={<TreeView />} />
      <Route path="/team" element={<Team />} />
      <Route path="/team-direct" element={<TeamDirect />} />
      <Route path="/stakings" element={<StakingList />} />
      <Route path="/staking-income" element={<StakingIncomeList />} />
      <Route path="/community-income" element={<Communityincome />} />
      <Route path="/roiofroi-income" element={<ROIofROIincome />} />
      <Route path="/royalty-income" element={<TeamRoyaltyincome />} />
      <Route path="/withdrawal" element={<Withdrawal />} />
      <Route path="/withdrawal-history" element={<WithdrawalHistory />} />
      <Route path="/table" element={<CommanTable />} />
      <Route path="/gaming-wallet" element={<GamingWallet />} />
      <Route path="/certificate" element={<Certificate />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/:link_ref_address" element={<Login />} />
      <Route path="/profile" element={<Profiledata />} />
      <Route path="/gaming-history" element={<GamingTransfer />} />
      <Route path="/achievements" element={<Achievements />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
