import React, { useContext } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import DashboardHero from './DashboardHero'
import Staking from './Staking'
import Statastics from './Statastics'
import Games from './Games'
import Travellfund from './Travellfund'
import Incomes from './Incomes'
import { ProjectContext } from '../../ProjectContext'

export default function Dashboard() {

    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                <DashboardHero />
                <br />
                <br />
                <Incomes />
                <Staking />
            </div>
            <br />
            {/* <DashboardHeader /> */}
            {/* <Statastics /> */}
            {/* <Travellfund /> */}
            {/* <Games /> */}
            {/* <Turnsament /> */}
            {/* <Table /> */}
            {/* <Staking /> */}
        </>
    )
}
