import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Tree from 'react-d3-tree';
import { Modal } from 'react-bootstrap';


export default function TreeView() {
  const { account, getudata, copyaddress, toastError, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState({ name: "you" });
  const [fdata, setfdata] = useState({ name: "you" });
  const [popupdata, setpopupdata] = useState(null);
  const [popupdataloading, setpopupdataloading] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [tooltipText, setTooltipText] = useState('');
  const [show, setShow] = useState(false);
  const [nodetofind, setnodetofind] = useState(null);

  const tooltipRef = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const CustomLabel = ({ nodeData }) => (
    <g>
      <text x="0" y="0" textAnchor="middle" fill="#000000">
        {nodeData.name}
      </text>
    </g>
  );
  const treeConfig = {
    nodeSize: { x: 800, y: 500 },
    separation: { siblings: 0.6, nonSiblings: 0.8 },
    initialDepth: 2,
    nodeSvgShape: {
      shape: "rect",
      shapeProps: {
        width: 0,
        height: 0,
        x: -20,
        y: 20,
        stroke: "#2F80ED"
      }
    },
    allowForeignObjects: true,
    nodeLabelComponent: {
      render: <CustomLabel />,
      foreignObjectWrapper: {
        y: 0,
        x: 0,
      },
    },
    pathFunc: "step",
  };

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "tree",
        // address: "0xfded9e02beccedbddf19a1cf3a86769fca363427",
        address: account,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        console.log("dasrefff", res.data);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          setisLoading(false);
          return "";
        }
        setdata(res.data.data);
        setfdata(res.data.data);
        setisLoading(false);

      });
  };
  const getPopupData = async (address) => {
    setShow(true)
    setpopupdataloading(true)
    var data = await getudata(address);
    if (data.data.error) {
      setpopupdata(null);
      setpopupdataloading(false)
      return "";
    }
    setpopupdata(data.data.data);
    setpopupdataloading(false)
  }

  function findDataByName() {

    if (!nodetofind) {
      setfdata(data)
      return
    }
    let result = null;

    function search(node) {
      if (node.name === nodetofind) {
        result = node;
        return;
      }

      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          search(child);
          if (result) return;  // Early exit if result is found
        }
      }
    }

    for (let item of data) {
      search(item);
      if (result) break;  // Early exit if result is found
    }
    if (!result) {
      toastError("No data found")
      return
    }
    console.log("foundData", result);
    setfdata(result)
    return result;
  }

  useMemo(() => {
    getData()
  }, [account]);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  // Dynamically set the initial translate value based on the container size
  React.useEffect(() => {
    const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();
    setTranslate({
      x: dimensions.width / 2,
      y: dimensions.height / 4,
    });
  }, []);
  const handleNodeMouseOver = (event, nodeDatum) => {

    // Set the tooltip text based on the node data
    console.log(event);
    setTooltipText(nodeDatum.name);

    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'block';
      tooltipRef.current.style.top = `${event.clientY}px`;
      tooltipRef.current.style.left = `${event.clientX}px`;
    }
  };

  const handleNodeMouseOut = () => {
    // Clear the tooltip text
    setTooltipText('');
    // Hide the tooltip
    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none';
    }

  };

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g onMouseOver={(e) => handleNodeMouseOver(e, nodeDatum)} onMouseOut={handleNodeMouseOut}>
      {/* <rect
        width="120"
        height="50"
        x="-60"
        y="-25"
        onClick={toggleNode}
        style={{ cursor: 'pointer' }}
      /> */}
      {/* <text
        textAnchor="middle"
        alignmentBaseline="central"
        fontSize="12px"
      >
        {nodeDatum.name}
      </text> */}
      <foreignObject x="-130" y="-300" width="300" height="480"
        style={{ cursor: 'pointer' }}>
        {/* <div className='fortree' onClick={toggleNode}>
          {nodeDatum.name} <br />
          <span className='badge badge-success'>sss</span>
        </div>
        <button className='th-btn btn-sm' style={{ width: "50px", height: "50px" }} onClick={() => getPopupData(nodeDatum.address)}>Click</button> */}
        <div className="commando-card p-0  text-center" style={{ backgroundColor: "#0f212e" }}>
          <div className="commando-card-bg-shape background-image" />
          <div className="">
            <i className={`fa-solid fa-user ${nodeDatum.status ? "text-success" : "text-white"} `} style={{ fontSize: '140px' }}></i>
            <h4 className="commando-card-title text-white text-break">{nodeDatum.name}</h4>
          </div>
          <ul className="commando-list-wrap d-block">
            {nodeDatum.children?.length ?
              <li className="commando-single-list px-2" onClick={toggleNode}>
                <div className="icon pt-1" >
                  <i className={`fa-solid fa-arrow-down`} style={{ fontSize: '27px' }}></i>
                  <span className='text-white'>Expand</span>
                </div>
              </li>
              :
              ''}
            <li className="commando-single-list px-4" onClick={() => getPopupData(nodeDatum.address)}>
              <div className="icon pt-1">
                <i className={`fa-solid fa-drivers-license`} style={{ fontSize: '23px' }}></i>
                <span className='text-white '>Details</span>
              </div>
            </li>
          </ul>
        </div>
      </foreignObject>
      {/* <foreignObject width="100" height="100" x="-50" y="-50" onClick={toggleNode}>
        <div className="custom-node">
          <h3>{nodeDatum.name}</h3>
        </div>
      </foreignObject> */}

    </g>
  );

  return (
    <>
      <DashboardSidebar />
      <div className='home-section '>
        {/* <DashboardHeader />s */}
        <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
          <div className="container " id="treeWrapper" style={{ width: '100%', height: '100vh', }} >
            {/* coming soon... */}
            <div className="row">
              <div className="form-group style-border2 col-md-3 ">
                <input type="text" className="form-control" placeholder="Enter Username" onChange={(e) => setnodetofind(e.target.value)} />
                {/* <button className="th-btn btn-xs" style={{minWidth:"97px"}} onClick={() => findDataByName()}>Find<i className="fa-solid fa-arrow-right ms-2" /></button> */}
              </div>
              <div className="form-group col-md-3 ">
                {/* <input type="text" className="form-control" placeholder="Enter Username" onChange={(e) => setnodetofind(e.target.value)} /> */}
                <button className="th-btn btn-xs" style={{ minWidth: "97px" }} onClick={() => findDataByName()}>Find<i className="fa-solid fa-arrow-right ms-2" /></button>
              </div>
            </div>
            {isLoading ?
              <div className="text-center fs-2">Loading...</div> :
              <Tree
                data={fdata}
                orientation="vertical"
                {...treeConfig}
                translate={translate}
                scaleExtent={{ min: 0.1, max: 0.5 }}
                isInitialRenderForDataset={true}
                enableLegacyTransitions={true}
                renderCustomNodeElement={renderRectSvgNode}
              />
            }
          </div>
        </div>
      </div >

      <Modal show={show} onHide={handleClose} centered>
        <div className='d-flex justify-content-center text-dark'>
          <div class="modal-content ">
            <div className="modal-header d-flex justify-content-between">
              <h5 className="modal-title" id="exampleModalLabel">Details</h5>
              <button type="button" className="close" onClick={handleClose}>
                <span aria-hidden="true" >×</span>
              </button>
            </div>
            <Modal.Body>
              <div className="row ">
                {popupdataloading ? "Loading..." :
                  <>
                    {popupdata ?
                      <>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between">
                          <span>Rank</span> <span>{popupdata.rank ? popupdata.rank : 'No Rank'}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Referral ID</span> <span class="text-break text-end" onClick={() => copyaddress(popupdata.user_id)}>{popupdata.user_id} <i className='fa fa-copy'></i></span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Username</span> <span class="text-break text-end">{popupdata.uname}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Address: </span> <span class="text-break text-end " onClick={() => copyaddress(popupdata.address)}>{formatAddress(popupdata.address)} <i className='fa fa-copy'></i></span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Direct Business</span> <span class="text-break text-end">$ {popupdata.direct_team_business}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Team Business</span> <span class="text-break text-end">$ {popupdata.team_business}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Directs</span> <span class="text-break text-end">{popupdata.d_team}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Team</span> <span class="text-break text-end">{popupdata.team}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Self Investment</span> <span class="text-break text-end">{popupdata.t_staking}</span></div>
                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Date of Joining</span> <span class="text-break text-end">{popupdata.createdAt}</span></div>
                      </> : ''}
                  </>}
              </div>
            </Modal.Body>
          </div>

        </div>
      </Modal>

    </>
  )
}
