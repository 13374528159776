import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import CountdownTimer from './CountdownTimer'
import { ProjectContext } from '../../ProjectContext';
import Modal from 'react-bootstrap/Modal';

export default function DashboardHero() {
    const { copyaddress,
        account,
        dbuser,
    } = useContext(ProjectContext)
    const [show, setShow] = useState(false);

    function getNextDateAfter7Days(inputDate) {
        if (!inputDate) {
            return null
        }
        // console.log("0x6652e765ccd969816503c40da6e91816517b8691",inputDate);
        let date = new Date(inputDate);
        let today = new Date();

        // Set today's time to the start of the day for accurate comparison
        // today.setHours(0, 0, 0, 0);

        // Keep adding 7 days until the date is greater than today
        while (date <= today) {
            date.setDate(date.getDate() + 7);
        }

        return date;
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const shareUrl = `${process.env.REACT_APP_LINK}login/${dbuser ? dbuser.user_id : ''}`;
    const title = 'Share your YBL referral link and earn rewards';

    const handleShare = (platform) => {
        let shareLink = '';
        switch (platform) {
            case 'email':
                shareLink = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareUrl)}`;
                break;
            case 'whatsapp':
                shareLink = `https://wa.me/?text=${encodeURIComponent(title)}%20${encodeURIComponent(shareUrl)}`;
                break;
            case 'telegram':
                shareLink = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(title)}`;
                break;
            case 'instagram':
                shareLink = `https://www.instagram.com/`;
                break;
            default:
                return;
        }
        window.open(shareLink, '_blank');
    };

    return (
        <>
            <div className="container th-container4">
                {/* <div className='text-center d-flex justify-content-center'>
                    <div style={{ width: "350px" }}>
                        <h6 className='text-white text-center'>Next Distribution in</h6>
                        <h6 className='text-white text-start '>
                            <CountdownTimer targetDate={getNextDateAfter7Days(dbuser !== null ? dbuser.s_date : null)} />
                        </h6>
                    </div>
                </div> */}
                <div className="cta-area-1 pt-4" style={{ backgroundImage: `url('./assets/img/dashboard_img.png')`, borderRadius: "25px", backgroundSize: "auto", backgroundRepeat: "no-repeat", backgroundPosition: "50% 50%" }}>
                    {/* <div className="cta-bg-shape-border">
                        <svg width={1464} height={564} viewBox="0 0 1464 564" fill="none" xmlns="http://www.w3.org/2000/svg">

                        <path d="M1463.5 30V534C1463.5 550.292 1450.29 563.5 1434 563.5H1098H927.426C919.603 563.5 912.099 560.392 906.567 554.86L884.14 532.433C878.42 526.713 870.663 523.5 862.574 523.5H601.426C593.337 523.5 585.58 526.713 579.86 532.433L557.433 554.86C551.901 560.392 544.397 563.5 536.574 563.5H366H30C13.7076 563.5 0.5 550.292 0.5 534V30C0.5 13.7076 13.7076 0.5 30 0.5H366H536.574C544.397 0.5 551.901 3.60802 557.433 9.14034L579.86 31.5668C585.58 37.2866 593.337 40.5 601.426 40.5H862.574C870.663 40.5 878.42 37.2866 884.14 31.5668L906.567 9.14035C912.099 3.60803 919.603 0.5 927.426 0.5H1098H1434C1450.29 0.5 1463.5 13.7076 1463.5 30Z" stroke="url(#paint0_linear_202_547)" />
                        <defs>
                            <linearGradient id="paint0_linear_202_547" x1={0} y1={0} x2="1505.47" y2="412.762" gradientUnits="userSpaceOnUse">
                                <stop offset={0} stopColor="var(--theme-color)" />
                                <stop offset={1} stopColor="var(--theme-color2)" />
                            </linearGradient>
                        </defs>
                    </svg>
                    </div>
                    <div className="cta-wrap-bg bg-repeat" data-bg-src="assets/img/bg/jiji-bg.png" data-mask-src="assets/img/shape/cta-bg-shape1.svg">
                        <div className="cta-bg-img"><img src="assets/img/bg/cta-sec1-bg.png" alt="img" /></div>
                        <div className="cta-thumb"><img src="assets/img/cta1-1.jpg" alt="img" /></div>
                    </div> */}
                    <div className="cta-wrap">
                        <div className="row me-lg-5 me-0 justify-content-end">
                            <div className="col-xl-6">

                                <div className="title-area mb-0 ps-lg-5 ps-0 custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s"><span className="sub-title"># World Best Gaming Site</span>
                                    <h2 className="sec-title">Your <span className="text-theme fw-medium">YBLWin Account!</span></h2>
                                    <h2 className="sec-title"><span className="text-theme fw-medium">{dbuser ? dbuser.uname : 'Guest'}</span></h2>

                                    <p className="mt-30 mb-30 text-break text-white" onClick={() => copyaddress(account)}>{account} <i className='fa fa-copy'></i></p>
                                    {dbuser !== null
                                        ? dbuser.status ?
                                            <h2 className="sec-title">
                                                {dbuser !== null
                                                    ? dbuser.income_capability : '0'}
                                                <span className="text-theme">X</span> - Capability
                                            </h2> : <h2 className="sec-title">
                                                <span className="text-theme">-</span> - Capability
                                            </h2> : ""}
                                    <h2 className="sec-title"><span className="text-theme fw-medium">{dbuser !== null
                                        ? dbuser.rank !== null ? dbuser.rank : 'No Rank'
                                        : "No Rank"}</span> - Rank</h2>
                                </div>
                            </div>
                            {/* <div className="col-xl-3">
                                <CircularProgressbarWithChildren
                                    value={80}
                                    text={`${10}Rank`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                        strokeLinecap: "butt",
                                        pathColor: "#3a49fc",
                                        textColor: "#3a49fc",
                                        fontWeight: "bold",

                                    })}
                                >
                                    <RadialSeparators
                                        count={12}
                                        style={{
                                            background: "#fff",
                                            width: "2px",
                                            // This needs to be equal to props.strokeWidth
                                            height: `${10}%`
                                        }}
                                    />
                                </CircularProgressbarWithChildren>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <section className="tournament-sec-v2 pt-5" data-bg-src="assets/img/bg/tournament-sec2-bg.png">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-12">
                            <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s"><span className="sub-title style2"># Join Play-To-Earn Era</span>
                                <h2 className="sec-title">Earn Big on YBLWin </h2>
                            </div>
                        </div>
                        <div className="col-lg-12 custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <div className="tournament-filter-btn2 filter-menu filter-menu-active">
                                <Link to="/stake" data-filter="*" className="tab-btn th-btn style-border3" type="button"><span className="btn-border">Invest Now
                                    <i className="fa-solid fa-arrow-right ms-2" /></span></Link>
                                <button data-filter=".cat1" className="tab-btn th-btn style-border3" type="button" onClick={handleShow} ><span className="btn-border">Refer & Earn
                                    <i className="fa-solid fa-arrow-right ms-2" /></span></button>
                                <Link to="/withdrawal" data-filter=".cat2" className="tab-btn th-btn style-border3" type="button"><span className="btn-border">Withdrwal
                                    <i className="fa-solid fa-arrow-right ms-2" /></span></Link>
                                <Link to="/gaming-wallet" data-filter=".cat2" className="tab-btn th-btn style-border3" type="button"><span className="btn-border">Gaming Wallet
                                    <i className="fa-solid fa-arrow-right ms-2" /></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tournament-sec-5 overflow-hidden" data-overlay="title">
                <div className="tournament-sec-bg-shape" />
                <div className="container">
                    <div className="row gy-4 filter-active justify-content-center">
                        <div className="col-lg-6 col-md-12 filter-item tour-cat1 tour-all">
                            <div className="game-card style2 pt-2 mt-5">
                                <div className="game-card-img d-flex  justify-content-between">
                                    <div className="game-logo"><img src="assets/img/staking.svg" alt="game logo" /></div>
                                </div>
                                <div className="game-card-details d-block flex-wrap">
                                    <div className=' d-flex justify-content-between'>
                                        <h3 className="sec-title text-start mb-5">Capping
                                        </h3>
                                        <h2 className="text-theme">{dbuser ? dbuser.income_capability : 0}x</h2>
                                    </div>
                                    <div className="box-title fs-2 d-flex justify-content-between">
                                        <div>
                                            <h3 className="skill-feature_title"><span className="text-theme">${dbuser ? dbuser.team_business : 0}</span></h3>
                                            <h5 className="skill-feature_title">Team Business</h5>
                                        </div>
                                        <div>
                                            <h3 className="skill-feature_title"><span className="text-theme"> {dbuser ? dbuser.team : 0}</span></h3>
                                            <h5 className="skill-feature_title">Team</h5>
                                        </div>
                                    </div>
                                    <div className="media-left">
                                        <div className="skill-feature mb-2">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" title={`${dbuser ? dbuser.x_4per : 0}%`} style={{ width: `${dbuser ? dbuser.x_4per : 0}%` }} />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 filter-item tour-cat1 tour-all">
                            <div className="game-card style2 pt-2 mt-5">
                                <div className="game-card-img d-flex justify-content-between">
                                    <div className="game-logo"><img src="assets/img/staking.svg" alt="YBL" /></div>
                                    {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                </div>
                                <div className="game-card-details d-block flex-wrap">
                                    <h3 className="sec-title text-start">Team Revenue Share</h3>
                                    <h3 className="box-title fs-2 d-lg-flex d-block justify-content-between">
                                        {/* <div className='pt-2'>
                                            <div>
                                                <h3 className="skill-feature_title"><span className="text-theme"> ${dbuser ? dbuser.t_30_staking : 0}</span></h3>
                                                <h3 className="skill-feature_title">Team Business</h3>
                                            </div>
                                            <div>
                                                <h3 className="skill-feature_title"><span className="text-theme">{dbuser ? dbuser.d_team : 0}</span></h3>
                                                <h3 className="skill-feature_title">Direct Team</h3>
                                            </div>
                                        </div> */}
                                        {/* <div className='d-block'> */}
                                        <h5 className='text-white text-start'>
                                            {/* Level */}
                                            <CountdownTimer targetDate={dbuser ? new Date(new Date(dbuser.s_date).setDate(new Date(dbuser.s_date).getDate() + 30)) : null} />
                                        </h5>
                                        <h4 className='text-white text-lg-end text-center  '>Level <span className='text-theme'> L1,L2</span></h4>
                                        {/* </div> */}
                                    </h3>
                                    <div className="media-left">
                                        <div className="skill-feature">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" title={`${dbuser ? dbuser.t_30_per : 0}%`} style={{ width: `${dbuser ? dbuser.t_30_per : 0}%` }} />
                                            </div>
                                            <div className="progress-value-wrap d-flex justify-content-between">
                                                <div className="progress-value dwar"><span className="counter-number">$0</span></div>
                                                <div className="progress-value"><span className="counter-number">$10000</span></div>
                                                {/* <div className="progress-value lose">LOSSES <span className="counter-number">20</span>%</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={show} onHide={handleClose} centered>
                <div className='d-flex justify-content-center'>
                    <div class="modal-content">
                        <div className="modal-header d-flex justify-content-between">
                            <h5 className="modal-title" id="exampleModalLabel">Invitee</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <Modal.Body>
                            <div className="modal-body py-0">
                                <form>
                                    <div className="form-group" onClick={() => copyaddress(shareUrl)}>
                                        {/* <label htmlFor="recipient-name" className="col-form-label">Recipient:</label>. */}
                                        <input type="text" className="form-control" value={shareUrl} />
                                        <i className='fa fa-copy copy' />
                                    </div>
                                </form>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='d-flex justify-content-center'>
                            <div className="th-widget-contact">
                                <div className="th-social style-mask ">
                                    <a className="facebook " href="#" onClick={() => handleShare('email')}><i className="fa fa-envelope" /></a>
                                    <a className="twitter " href="#" onClick={() => handleShare('whatsapp')}><i className="fab fa-whatsapp" /></a>
                                    <a className="instagram " href="#" onClick={() => handleShare('instagram')}><i className="fab fa-instagram" /> </a>
                                    <a className="linkedin " href="#" onClick={() => handleShare('telegram')}><i className="fab fa-telegram" /></a>
                                </div>
                            </div>
                        </Modal.Footer>
                    </div>

                </div>
            </Modal>
        </>
    )
}
