import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Stake() {
    // const { link_ref_address } = useParams();

    const { fdpkg, rdpkg, copyaddress,
        account,
        balance,
        rate,
        smuser,
        toastSuccess,
        library,
        dbuser,
        tokenrate, formatAddress,
        stakcontract,
        toastError,
        getudata,
        settings,
        esigner,
        ethers,
        getuserDB,
        connectMetamask,
    } = useContext(ProjectContext)

    const [amount, setamount] = useState(0);
    const [trdone, settrdone] = useState(false);
    const [tandc, settandc] = useState(false);
    const [hash, sethash] = useState("");
    const [referral, setreferral] = useState("");

    var navigate = useNavigate();
    function isDivisibleBy100(number) {
        return number % 50 === 0;
    }

    const onDeposit = async () => {
        settrdone(true);
        sethash("");
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!dbuser) {
            toastError("Register first");
            settrdone(false);
            navigate("/login");
            return;
        }
        console.log("dassd", amount);
        
        if (!amount) {
            toastError("Enter amount");
            settrdone(false);
            return;
        }
        if (!tandc) {
            toastError("Accept terms and conditions");
            settrdone(false);
            return;
        }
        if (amount < 50 || amount > 30000) {
            toastError("Min $50 and Max $30000 required");
            settrdone(false);
            return;
        }
        if (isDivisibleBy100(amount) === false) {
            toastError("Amount must be in multiple of 50");
            settrdone(false);
            return
        }


        // if (Number(balance) < Number(total_tokens)) {
        //   toastError("Insufficient balance");
        //   return;
        // }
        // if (!referral) {
        //     toastError("Enter Referral Address");
        //     settrdone(false);
        //     return;
        // // }
        // var data = await getudata(referral);

        // if (data.data.error) {
        //     toastError("Invalid Referral Address");
        //     settrdone(false);
        //     return "";
        // }
        // if (dbuser !== null && dbuser.last_staking > amount) {
        //     toastError(`Stake ${dbuser.last_staking} or more`);
        //     settrdone(false);
        //     return;
        // }
        let contract = new library.Contract(
            settings.contract,
            JSON.parse(settings.contract_abi),
            esigner
        );
        let token_contract = new library.Contract(
            settings.token,
            JSON.parse(settings.token_abi),
            esigner
        );
        const allowance = await token_contract.allowance(
            account,
            settings.contract
        );

        async function main() {
            // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
            //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
            //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
            //   );
            try {
                var stk = await contract.stake(
                    ethers.utils.parseEther(`${amount}`),
                    referral
                );
                // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

                const receipt = await stk.wait();
                sethash(receipt.transactionHash);
                // sethash(randomHash);
                toastSuccess("Transaction done");
                await axios
                    .post(process.env.REACT_APP_BACKEND_LINK, {
                        method: "transaction",
                        submethod: "insert",
                        key: process.env.REACT_APP_KEY,
                        address: account,
                        ref_address: referral,
                        hash: receipt.transactionHash,
                        amount: amount
                    })
                    .then((res) => {
                        console.log("res", res.data);
                        // var dedata = decryptData(res.data);
                        if (res.data.error) {
                            return "";
                        }
                    });
                getuserDB();
                settrdone(false);
                navigate("/dashboard");

            } catch (error) {
                console.log("Error", error);
                toastError("Transaction declined");
                settrdone(false);
                return;
            }

        }
        // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
        if (Number(ethers.utils.formatEther(allowance)) < amount) {
            // if (false) {
            try {
                var approv = await token_contract.approve(
                    settings.contract,
                    ethers.utils.parseEther("100000000")
                );
                const receipt = await approv.wait();
                console.log(receipt);
                toastSuccess("Token Approved");
                main();
            } catch (error) {
                console.log("error", error);
                toastError("Token not approved");
                settrdone(false);
                return;
            }
        } else {
            main();
        }
    };
    useEffect(() => {
        if (dbuser !== null) {
            if (dbuser.ref_address !== null) {
                setreferral(dbuser.ref_address);
            }
        }
    }, [dbuser, account]);

    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader /> */}
                <br /><br />
                <div className="contact-sec-1 bg-repeat overflow-hidden space" style={{ backgroundImage: `url("assets/img/bg/jiji-bg2.png")` }}>
                    <div className="container-fluid p-0">
                        <div className="contact-wrap-1">
                            <div className="row justify-content-around">
                                <div className="col-xxl-5 col-xl-6">
                                    <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <h2 className="sec-title text-white">Invest Amount &amp; Earn Reward </h2>
                                    </div>
                                    <div className="row">
                                        {/* {dbuser !== null ? (
                                            dbuser.ref_address !== null ? (
                                                <div className="form-group style-border2 col-md-12">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={referral}
                                                    />
                                                </div>
                                            ) : (
                                                <> <div className="form-group style-border2 col-md-12">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Referral Address"
                                                        value={referral}
                                                        onChange={(e) => { setreferral(e.target.value); }} />
                                                </div></>
                                            )
                                        ) : (
                                            <>
                                                <div className="form-group style-border2 col-md-12">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Referral Address"
                                                        value={referral}
                                                        onChange={(e) => { setreferral(e.target.value); }} />
                                                </div>
                                            </>
                                        )} */}
                                        <div className="form-group style-border2 col-md-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={account}
                                            />
                                        </div>
                                        <div className="form-group style-border2 col-md-12">
                                            <input type="number" className="form-control" placeholder="Amount" onChange={(e) => setamount(e.target.value)} /> <i className="fal fa-dollar" />
                                        </div>
                                        {hash ?
                                            <div className=" style-border2 col-md-12 d-flex justify-content-between">
                                                <span>Tnx Hash:</span>
                                                <span>{hash}</span>
                                            </div> : ''}
                                        <div className="form-group style-border2 col-md-12">
                                            <input type="checkbox" className="form-control" placeholder="Amount" onChange={(e) => setamount(e.target.value)} />
                                        </div>
                                        <div class="form-check ">
                                            <input className="form-check-input form-control" type="checkbox" id="flexCheckChecked" onChange={(event) => settandc(event.target.checked)} />
                                            <label className="form-check-label text-white" for="flexCheckChecked">
                                               <Link to="#"> By Investing, I agree to Terms and conditions.</Link>
                                            </label>
                                        </div>
                                        
                                        {trdone ? (
                                            <img
                                                className=" bd-gradient-btn rounded-pill w-10"
                                                src="/assets/img/loader.gif"
                                                alt="loading"
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            <div className="form-btn col-12 my-5">
                                                <button className="th-btn" onClick={() => onDeposit()}>Submit <i className="fa-solid fa-arrow-right ms-2" /></button>
                                            </div>
                                        )}
                                    </div>
                                    <p className="form-messages mb-0 mt-3" />
                                </div>
                                <div className="col-xxl-5 col-xl-6">
                                    <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <h2 className="sec-title text-white">Frequently Asked Questions About Our Esports &amp; Gaming Site
                                        </h2>
                                    </div>
                                    <div className="accordion custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s" id="faqAccordion">
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-1"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">What rewards do YBLWin pay to users?</button></div>
                                            <div id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">YBLWin distributes a variety of rewards to users including YBL token, crypto bonus, royalties and more</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-2"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">Which games users can play on YBLWin?
                                            </button></div>
                                            <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Users can play a wide range of games on YBLWin including casino, fantasy cricket, chess, carrom, pool and other arcade games. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-3"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">What additional rewards users can claim?</button></div>
                                            <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Users can earn referral rewards, team royalty, pool income and more.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </div >
        </>
    )
}
