import React, { useContext } from 'react'
import { ProjectContext } from '../../ProjectContext'

export default function Staking() {
    const { dbuser, Ranks } = useContext(ProjectContext)

    return (
        <>
            <section className="pb-0">
                <div className="container z-index-common space">
                    <div className="cta-wrsap2">
                        <div className="cta-border-sshape" />
                        <div className='row'>
                            <div className='col-12'>
                                <div className="cta-wrap-content" >
                                    <div className="cta-border-shape2" />
                                    <div className="title-area mb-0 custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <h2 className="sec-title px-2">Achieve higher rank, Unlock higher rewards</h2>
                                        <div id="steps">
                                            {Ranks.map((item, i) => {
                                                return (
                                                    <img src={`assets/img/client/1-${item.rank_number}.png`} style={{opacity:item.rank_number=== (dbuser ? dbuser.rank_number:'') ? "1":'0.2'}} alt="Image" className='px-3' />
                                                    // <div className={`step ${item.rank_number=== (dbuser ? dbuser.rank_number:'') ? "active":''}`} data-desc={item.rank}>{i+1}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >


        </>
    )
}
