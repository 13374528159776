import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Comman/Pagination';

export default function TeamRoyaltyincome() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [sumIncome, setsumIncome] = useState(0);
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "royalty",
                submethod: "getbyid",
                // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
                address: account,
                fromdate: fromdate,
                todate: todate,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
                const sum = res.data.data.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.income;
                }, 0);
                setsumIncome(sum)

            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);

    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader />s */}
                <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                    <div className="container">
                        <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <h2 className="sec-title">Leadership Bonus</h2>
                        </div>
                        <div className='row d-flex align-items-center justify-content-center'>
                            <div className="col-lg-2">
                                <label htmlFor="" className='text-start text-white'>From</label>
                                <div className="form-group">
                                    <input className="form-control" type="date" placeholder="Enter level" value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <label htmlFor="" className='text-start text-white'>To</label>
                                <div className="form-group">
                                    <input className="form-control" type="date" placeholder="Enter level" value={todate} onChange={(e) => settodate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <button onClick={() => getData()} className="th-btn">Search NOW <i className="far fa-arrow-right ms-2" /></button>
                            </div>
                        </div>
                        
                        <div className="table-responsive">

                            <table className="table tournament-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Display name</th>
                                        <th scope="col">Rank</th>
                                        <th scope="col">Team Business</th>
                                        <th scope="col">Reward(%)</th>
                                        <th scope="col">Reward</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={9}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    {data.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td >
                                                    {ele.id}
                                                </td>
                                                <td >
                                                    <span
                                                        onClick={() => copyaddress(ele.hash)}
                                                    >
                                                        {formatAddress(ele.hash)}{" "}
                                                        <i className="fa fa-copy"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span
                                                        // onClick={() => copyaddress(ele.from)}
                                                        onClick={() => copyaddress(ele.from_uname)}
                                                    >
                                                        {/* {formatAddress(ele.from)}{" "} */}
                                                        {formatAddress(ele.from_uname)}{" "}
                                                        <i className="fa fa-copy"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {ele.rank}
                                                </td>
                                                <td>
                                                    $ {ele.income}
                                                </td>
                                                <td>
                                                    {ele.income_per} %
                                                </td>
                                                <td>
                                                    $ {ele.team_business}
                                                </td>

                                                <td>
                                                    {ele.datetime}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col">Total</th>
                                        <th scope="col">$ {sumIncome}</th>
                                    </tr>
                                </thead>

                            </table>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pages={pages}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
