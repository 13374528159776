import React from 'react'

export default function Footer() {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <>
            <footer className="footer-wrapper footer-layout1">
                <div className="container">
                    <div className="footer-top text-center">
                        <div className="footer-logo bg-repeat" data-bg-src="/assets/img/bg/jiji-bg.png"><a href="index.html"><span data-mask-src="/assets/img/logo.png" className="logo-mas" /> <img src="/assets/img/logo.png" alt="Bame" /></a></div>
                    </div>
                </div>
                <div className="container">
                    <div className="widget-area">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="widget footer-widget d-flex justify-content-center">
                                    <div className="th-widget-contact">
                                        <div className="th-social style-mask"><a className="facebook" href="https://www.facebook.com/profile.php?id=61560853537928&is_tour_dismissed"><i className="fab fa-facebook-f" /></a>
                                            <a className="twitter" href="https://x.com/YblWin"><i className="fab fa-twitter" /></a>
                                            <a className="instagram" href="https://www.instagram.com/yblwin/"><i className="fab fa-instagram" /> </a>
                                            <a className="linkedin" href="https://www.reddit.com/r/YblWin/"><i className="fab fa-reddit" /></a>
                                            <a className="google-play" href="https://www.youtube.com/@YBLWIN"><i className="fab fa-youtube" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap text-center bg-repeat" data-bg-src="/assets/img/bg/jiji-bg.png">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6">
                                <p className="copyright-text bg-repeat" data-bg-src="/assets/img/bg/jiji-bg.png"><i className="fal fa-copyright" /> Copyright {year} <a href="index.html">YblWin</a> All Rights
                                    Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}
