import React, { useContext, useEffect, useState } from 'react'
import Header from './Comman/Header'
import Footer from './Comman/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ProjectContext } from '../ProjectContext'
import axios from 'axios'

export default function Login() {
    const { link_ref_address } = useParams();

    const { account, copyaddress, dbuser, toastSuccess, toastError, formatAddress, getuserDB } = useContext(ProjectContext)
    const [uname, setuname] = useState(null);
    const [m_number, setm_number] = useState(null);
    const [dname, setdname] = useState(null);
    const [email, setemail] = useState(null);
    const [reff_address, setreff_address] = useState(null);
    const [address, setaddress] = useState(null);
    const [tandc, settandc] = useState(false);

    var navigate = useNavigate();

    const updateData = async () => {
        const isValidWeb3Address = taddress => /^0x[a-fA-F0-9]{40}$/.test(taddress);
        if (!isValidWeb3Address(address)) {
            toastError("Invalid Address");
            return
        }
        if (!tandc) {
            toastError("Accept terms and conditions");
            return;
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "update",
                key: process.env.REACT_APP_KEY,
                address: address,
                uname: uname,
                dname: dname,
                email: email,
                ref_address: reff_address
            })
            .then((res) => {
                console.log("res", res.data);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess(res.data.data);
                navigate("/dashboard");
                getuserDB()
            });
    }
    useEffect(() => {
        if (dbuser !== null) {
            navigate("/dashboard");

            // setemail(dbuser.email)
            // setdname(dbuser.dname)
            // setuname(dbuser.uname)
            // setreff_address(dbuser.ref_address)

        }
    }, [dbuser])
    useEffect(() => {
        setaddress(account)
    }, [account])
    useEffect(() => {
        if (dbuser !== null) {
            if (dbuser.referral !== null) {
                // setreff_address(dbuser.user);
            }
        } else {
            setreff_address(link_ref_address);
        }
    }, [link_ref_address, dbuser, account]);

    return (
        <>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="contact-sec-1 bg-repeat overflow-hidden space" style={{ backgroundImage: `url("assets/img/bg/jiji-bg2.png")` }}>
                <div className="container-fluid p-0">
                    <div className="contact-wrap-1">
                        <div className="row justify-content-around">
                            <div className="col-xxl-5 col-xl-6">
                                <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                    <h2 className="sec-title text-white">Login, Play &amp; Enjoy Reward </h2>
                                </div>
                                <div className="row">
                                    <div className="form-group style-border2 col-md-12">
                                        <input type="text" className="form-control" name="text" id="text" placeholder="Display Name" onChange={(e) => setdname(e.target.value)} value={dname} /> <i className="fal fa-user" />
                                    </div>
                                    <div className="form-group style-border2 col-md-12">
                                        <input type="text" className="form-control" name="uname" id="uname" placeholder="User Name" onChange={(e) => setuname((e.target.value).replace(/\s/g, ""))} value={uname} /> <i className="fal fa-user" />
                                    </div>
                                    <div className="form-group style-border2 col-md-12">
                                        <input type="text" className="form-control" name="mobile" id="mobile" placeholder="Mobile Number" onChange={(e) => setm_number(e.target.value)} value={m_number} /> <i className="fal fa-phone" />
                                    </div>
                                    <div className="form-group style-border2 col-md-12">
                                        <input type="text" className="form-control" name="email" id="email" placeholder="Email" onChange={(e) => setemail(e.target.value)} value={email} /> <i className="fal fa-envelope" />
                                    </div>
                                    <div className="form-group style-border2 col-md-12">
                                        <input type="text" className="form-control" onChange={(e) => setaddress(e.target.value)} value={address} placeholder='Enter Address' /> <i className="fal fa-map-marker" />
                                    </div>
                                    <div className="form-group style-border2 col-md-12">
                                        <input type="text" className="form-control" name="reff" id="reff" placeholder="Referral ID" onChange={(e) => setreff_address(e.target.value)} value={reff_address} />
                                    </div>
                                    <div class="form-check ">
                                        <input className="form-check-input form-control" type="checkbox" id="flexCheckChecked" onChange={(event) => settandc(event.target.checked)} />
                                        <label className="form-check-label text-white" for="flexCheckChecked">
                                            <Link to="#"> By Investing, I agree to Terms and conditions.</Link>
                                        </label>
                                    </div>
                                    <div className="form-btn col-12 mt-3 my-5">
                                        <button onClick={() => updateData()} className="th-btn">Register <i className="fa-solid fa-arrow-right ms-2" /></button>
                                    </div>
                                </div>
                                <p className="form-messages mb-0 mt-3" />
                            </div>
                            <div className="col-xxl-5 col-xl-6">
                                <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                    <h2 className="sec-title text-white">Frequently Asked Questions About Our Esports &amp; Gaming Site
                                    </h2>
                                </div>
                                <div className="accordion custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s" id="faqAccordion">
                                    <div className="accordion-card">
                                        <div className="accordion-header" id="collapse-item-1"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">What rewards do YBLWin pay to users?</button></div>
                                        <div id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p className="faq-text">YBLWin distributes a variety of rewards to users including YBL token, crypto bonus, royalties and more</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-card">
                                        <div className="accordion-header" id="collapse-item-2"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">Which games users can play on YBLWin?
                                        </button></div>
                                        <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p className="faq-text">Users can play a wide range of games on YBLWin including casino, fantasy cricket, chess, carrom, pool and other arcade games. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-card">
                                        <div className="accordion-header" id="collapse-item-3"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">What additional rewards users can claim?</button></div>
                                        <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p className="faq-text">Users can earn referral rewards, team royalty, pool income and more.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
