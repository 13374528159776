/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Pagination = ({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  pages,
}) => {
  return (
    <>
      <div className="th-pagination text-center pt-50">
          <ul>
            <li className={` page-item ${currentPage === 1 ? "active" : ""}`}>
              <a
                type="button"
                
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1 ? true : false}
              ><span className="btn-border" />
                {"<<"}
              </a>
            </li>
            {currentPage - 2 > 0 ? (
              <li className=" page-item">
                <a
                  type="button"
                  
                  onClick={() => setCurrentPage((prev) => prev - 2)}
                ><span className="btn-border" />
                  {currentPage - 2}
                </a>
              </li>
            ) : (
              ""
            )}
            {currentPage - 1 > 0 ? (
              <li className=" page-item">
                <a
                  type="button"
                  
                  onClick={() => setCurrentPage((prev) => prev - 1)}
                ><span className="btn-border" />
                  {currentPage - 1}
                </a>
              </li>
            ) : (
              ""
            )}
            <li className=" page-item active">
              <a type="button"  disabled={true}>
              <span className="btn-border" />
                {currentPage}
              </a>
            </li>
            {currentPage + 1 <= pages ? (
              <li className=" page-item">
                <a
                  type="button"
                  
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                  <span className="btn-border" />
                  {currentPage + 1}
                </a>
              </li>
            ) : (
              ""
            )}
            {currentPage + 2 <= pages ? (
              <li className=" page-item">
                <a
                  type="button"
                  
                  onClick={() => setCurrentPage((prev) => prev + 2)}
                >
                  <span className="btn-border" />
                  {currentPage + 2}
                </a>
              </li>
            ) : (
              ""
            )}
            <li
              className={` page-item ${currentPage === pages ? "active" : ""}`}
            >
              <a
                type="button"
                
                onClick={() => setCurrentPage(pages)}
                disabled={currentPage === pages ? true : false}
              >
                <span className="btn-border" />
                {">>"}
              </a>
            </li>
          </ul>
      </div>
    </>
  );
};

export default Pagination;
